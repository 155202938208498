import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthModel, UserModel } from '../auth.models';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { NotificationType } from '../../interfaces/notification.enums';
import { ClientRoles } from '../../services/auth/auth.models'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{

  public loginForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;

  private subs: Array<Subscription> = [];
  public currentUser: AuthModel;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('(^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$)|(^[0-9]{10}$)')]],
      password: ['', Validators.required]
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.loginForm.controls
  }

  private loginUser(): UserModel {
    return {
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value
    }
  }

  public onSubmit(): void {
    this.submittedLogin = true;
    console.log(this.loginForm.controls["email"])

    if (this.loginForm.invalid) {
      return;
    }

    const user: UserModel = this.loginUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.login(user)
        .pipe(
          map(() => {
            this.authService.currentUser.subscribe((authenticatedUser: AuthModel) => this.currentUser = authenticatedUser);
            setTimeout(() => {
              if(this.currentUser.roles === 'superadmin' || this.currentUser.roles === 'admin' || this.currentUser.roles === 'supervisor'){
                this.router.navigateByUrl('/dashboard');
              }
              if(this.currentUser.roles === 'funcionario'){
                this.router.navigateByUrl('/dashboard');
              }
              if(this.currentUser.roles === 'user' || this.currentUser.roles === 'citizenhead') {
                this.router.navigateByUrl('/menus');
                // this.router.navigateByUrl('/reports/my-user-reports');
              }
            },500)
          }),
          catchError(err => {
            this.isLoading = false;
            this.sendNotification('Error al acceder', err.error?.message, NotificationType.error);
            return throwError(err);
          })
        ).subscribe()
    );
  }

  private sendNotification(title: string = 'Atención Ciudadana', message: string, type: string): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: 3500,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

}
