// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "http://173.212.231.241:4070/api",
  // API_URL: "https://veraxapi.stackcode.io/api",
  // API_URL: "http://173.212.231.241:4070/api",
  API_URL: "https://api.veracruzmunicipio.gob.mx/api",
  API_KEY: 'AIzaSyC9LwW4VAL-iBpGhoXNa110oPJMd8_Yt2Q',
};
