<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <!-- <p class="mb-0">Copyright 2022© Veracruz. Todos los derechos reservados.</p> -->
        </div>
        <div class="col-xs-12 col-md-8 footer-copyright">
            <p class="mb-0">Copyright 2022© Veracruz. Todos los derechos reservados.</p>
            <!-- <p class="pull-right mb-0">Creado por Stackcode con<i class="fa fa-heart"></i></p> -->
        </div>
    </div>
</div>