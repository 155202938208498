import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboardv2/dashboardv2.module').then(m => m.Dashboardv2Module),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Productos"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Ventas"
    }
  },
  {
    path: 'catalogs',
    loadChildren: () => import('../../components/catalogs/catalogs.module').then(m => m.CatalogsModule),
    data: {
      breadcrumb: "Catálogos"
    }
  },
  {
    path: 'surveys',
    loadChildren: () => import('../../components/surveys/surveys.module').then(m => m.SurveysModule),
    data: {
      breadcrumb: "Estudios"
    }
  },
  {
    path: 'quizs',
    loadChildren: () => import('../../components/quizs/quizs.module').then(m => m.QuizsModule),
    data: {
      breadcrumb: "Encuestas"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Cupones"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Páginas"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Inicio"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Usuarios"
    }
  },
  {
    path: 'clients',
    loadChildren: () => import('../../components/clients/clients.module').then(m => m.ClientsModule),
    data: {
      breadcrumb: "Clientes"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Ubicación"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'trips',
    loadChildren: () => import('../../components/trips/trips.module').then(m => m.TripsModule),
  },
  {
    path: 'work-orders',
    loadChildren: () => import('../../components/work-orders/work-orders.module').then(m => m.WorkOrdersModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Ajustes"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  }
];